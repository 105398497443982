import { Card, Spinner, Text, useBreakpoints } from '@shopify/polaris';
import React, { useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useFetchMonthlyVisitors } from './api';
import { format, getDaysInMonth } from 'date-fns';

const Graph = () => {
  const { data, isLoading } = useFetchMonthlyVisitors({}); // Assume data comes as { '2024-09-27': 5, ... }
  const { smDown } = useBreakpoints(); // Determine if the screen size is small

  const [xAxisDays, setXAxisDays] = useState([]);
  const [visitorsData, setVisitorsData] = useState([]);
  const [maxVisitors, setMaxVisitors] = useState(0);

  // Dynamically set the width based on whether the screen is small or not
  const chartWidth = smDown ? 360 : 1180; // Adjust width for small screens

  useEffect(() => {
    if (!isLoading && data) {
      // Get current month and year
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth(); // 0-indexed (September is 8)

      // Get the number of days in the current month
      const daysInMonth = getDaysInMonth(currentDate);

      // Generate x-axis labels as 'Sep 1', 'Sep 2', ..., 'Sep 30'
      const daysArray = Array.from({ length: daysInMonth }, (_, i) => format(new Date(year, month, i + 1), 'MMM d'));

      // Create a visitors array with null or 0 for days with no data
      const visitorsArray = daysArray.map((day, index) => {
        const formattedDate = format(new Date(year, month, index + 1), 'yyyy-MM-dd');
        return data[formattedDate] || 0; // Use the data value if exists, otherwise 0
      });

      // Set the max value of visitors for y-axis scaling
      const maxVal = Math.max(...visitorsArray);
      setMaxVisitors(maxVal);

      setXAxisDays(daysArray);
      setVisitorsData(visitorsArray);
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Card>
      <Text variant='headingMd'>Daily link visits</Text>
      <BarChart
        xAxis={[{ scaleType: 'band', data: xAxisDays }]} // xAxis will display 'Sep 1', 'Sep 2', etc.
        series={[{ data: visitorsData, color: "#4a4a4a" }]} // Visitors data array
        width={chartWidth} // Responsive width based on screen size
        height={smDown ? 300 : 500}
        yAxis={[
          {
            min: 0,
            max: maxVisitors + 2, // Ensure the max y-axis value is 2 more than the max visitors count
            ticksNumber: 5, // Approximate number of y-axis ticks
          },
        ]}
        grid={{ horizontal: true }} // Show grid for both x and y axes
      />
    </Card>
  );
};

export default Graph;
