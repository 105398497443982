import React from 'react';
import { BlockStack, Card, DataTable, Link, Text, Tooltip, useBreakpoints } from '@shopify/polaris';
import PageHeader from '../page_header';
import { useFetchVisitors } from './api';

const AFFILIATE_LINK_PREFIX = "https://partners.starapps.studio/";

const Visitors = () => {
  const { data, isLoading } = useFetchVisitors({});
  const { smDown } = useBreakpoints();
  let visited_rows = isLoading ? [] : data?.visited?.map((row) => {
    return [
      smDown ? <Tooltip
        content={row[0]}
      ><Link monochrome>{row[0].split(AFFILIATE_LINK_PREFIX)[1]}</Link></Tooltip> : row[0],
      row[1]
    ];
  });
  return (
    <BlockStack gap={400}>
      <PageHeader title="Visitors" />
      <Card padding={0}>
        <DataTable
          stickyHeader
          columnContentTypes={[
            'text',
            'text',
          ]}
          headings={[
            <Text fontWeight="bold" key={5}>Affiliate link</Text>,
            <Text fontWeight="bold" key={6}>Time</Text>,
          ]}
          rows={visited_rows}
          footerContent={visited_rows.length === 0 ? <Text>Visited links will be displayed here</Text> : null} // Optional footer message
        />
      </Card>
    </BlockStack>
  );
};

export default Visitors;