import axios from 'axios';
import {
  useQuery,
} from '@tanstack/react-query';

const VISITORS = "/partners/visitors_data";

export function useFetchVisitors({ ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['visitors'],
    queryFn: () => axios.get(VISITORS),
    select: (response) => response.data,
    placeholder: [],
  });
}