import axios from 'axios';
import {
  useQuery,
  useMutation,
} from '@tanstack/react-query';

const AFFILIATE_LINKS = "/partners/referral_plans_data";
const CREATE_NEW_PLAN = "/partners/create_new_plan";
let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const postMethod = (postBody, query) => axios.post(query, postBody, {
  headers: {
    'X-CSRF-Token': csrfToken
  }
});

export function useFetchAffiliateLinks({ ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['affiliate-links'],
    queryFn: () => axios.get(AFFILIATE_LINKS),
    select: (response) => response.data,
    placeholder: [],
  });
}

export function useCreateNewPlan({ ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn:(postBody) => postMethod(postBody, CREATE_NEW_PLAN),
  });
}