import axios from 'axios';
import {
  useQuery,
} from '@tanstack/react-query';

const GET_ANALYTICS = '/partners/all_referrals';

const getMethod = () => axios.get(GET_ANALYTICS);

export function useFetchAllReferrals({ ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['all-referrals'],
    queryFn: () => {
      const data = getMethod();
      return data;
    },
    select: (response) => response.data,
    placeholder: [],
  });
}