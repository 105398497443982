import axios from 'axios';
import {
  useQuery,
  useMutation,
} from '@tanstack/react-query';

const GET_ANALYTICS = '/partners/analytics'
const GET_MONTHLY_VISITORS = "/partners/monthly_visitors"

// const postMethod = (postBody) => axios.post(CREATE_PARTNER, postBody);

export function useFetchAnalytics({ ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['analytics'],
    queryFn: () => axios.get(GET_ANALYTICS),
    select: (response) => response.data,
    placeholder: [],
  });
}

export function useFetchMonthlyVisitors({ ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['monthly-visitors'],
    queryFn: () => axios.get(GET_MONTHLY_VISITORS),
    select: (response) => response.data.data,
    placeholder: [],
  });
}