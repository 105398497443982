/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { BlockStack, Card, InlineGrid, InlineStack, Text, useBreakpoints } from "@shopify/polaris";
import { useFetchAnalytics } from "./api";

function Analytics() {
  const { smUp } = useBreakpoints();

  const { data, loading } = useFetchAnalytics({});

  const totalEarnings = () => {
    return (
      <Card>
        <BlockStack gap="200">
          <Text as="h2" variant="bodyMd">Total earnings</Text>
          <InlineStack>
            {loading ?
              <Text as="span" variant="headingLg">--</Text> :
              (<InlineStack gap="100" blockAlign="end">
                <Text as="span" variant="headingLg">
                  $ {Number(data?.total_earnings || 0).toFixed(2)}
                </Text>
              </InlineStack>)
            }
          </InlineStack>
        </BlockStack>
      </Card>
    );
  };

  const monthlyEarnings = () => {
    return (
      <Card>
        <BlockStack gap="200">
          <Text as="h2" variant="bodyMd">Last 30 day earnings</Text>
          <InlineStack>
            {loading ?
              <Text as="span" variant="headingLg">--</Text> :
              (<InlineStack gap="100" blockAlign="end">
                <Text as="span" variant="headingLg">
                  $ {Number(data?.monthly_earnings || 0).toFixed(2)}
                </Text>
              </InlineStack>)
            }
          </InlineStack>
        </BlockStack>
      </Card>
    );
  };

  const pendingCommissions = () => {
    return (
      <Card>
        <BlockStack gap="200">
          <Text as="h2" variant="bodyMd">Pending commissions</Text>
          <InlineStack>
            {loading ?
              <Text as="span" variant="headingLg">--</Text> :
              (<InlineStack gap="100" blockAlign="end">
                <Text as="span" variant="headingLg">
                  $ {Number(data?.pending_commissions || 0).toFixed(2)}
                </Text>
              </InlineStack>)
            }
          </InlineStack>
        </BlockStack>
      </Card>
    );
  };

  const activeReferrals = () => {
    return (
      <Card>
        <BlockStack gap="200">
          <Text as="h2" variant="bodyMd">Active referrals</Text>
          <InlineStack>
            {loading ?
              <Text as="span" variant="headingLg">--</Text> :
              (<InlineStack gap="100" blockAlign="end">
                <Text as="span" variant="headingLg">{data?.referral_count}</Text>
              </InlineStack>)
            }
          </InlineStack>
        </BlockStack>
      </Card>
    );
  };


  return (
    <InlineGrid columns={smUp ? 4 : 1} gap="200">
      {totalEarnings()}
      {monthlyEarnings()}
      {pendingCommissions()}
      {activeReferrals()}
    </InlineGrid>
  );
}

export default Analytics;
