import { BlockStack, Button, Card, Text, TextField } from '@shopify/polaris';
import React from 'react'
const Name = ({ fields }) => {
  return (
    <Card>
      <BlockStack gap={200}>
        <BlockStack gap={200}>
          <Text>First name</Text>
          <TextField
            {...fields.first_name}
          />
        </BlockStack>
        <BlockStack gap={200}>
          <Text>Last name</Text>
          <TextField
            {...fields.last_name}
          />
        </BlockStack>
      </BlockStack>
    </Card>
  )
}

export default Name;