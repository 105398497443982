import axios from 'axios';
import {
  useQuery,
  useMutation,
} from '@tanstack/react-query';

const CREATE_PARTNER = '/partners/create'
let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const postMethod = (postBody, query) => axios.post(query, postBody, {
  headers: {
    'X-CSRF-Token': csrfToken
  }
});

export function useCreatePartner({ ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn:(postBody) => postMethod(postBody, CREATE_PARTNER),
  });
}