import axios from 'axios';
import {
  useQuery,
  useMutation,
} from '@tanstack/react-query';

const PAYOUTS = "/partners/payouts_data"
const PAYOUT_EMAIL = "/partners/payout_email"
let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const postMethod = (postBody, query) => axios.post(query, postBody, {
  headers: {
    'X-CSRF-Token': csrfToken
  }
});

export function usePayoutEmail({ ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn:(postBody) => postMethod(postBody, PAYOUT_EMAIL),
  });
}

export function useFetchPayouts({ ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['payouts'],
    queryFn: () => axios.get(PAYOUTS),
    select: (response) => response.data,
    placeholder: [],
  });
}