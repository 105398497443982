import axios from 'axios';
import {
  useQuery,
  useMutation,
} from '@tanstack/react-query';

const SETTINGS = "/partners/settings_data"
const PASSWORD = "/referrer_auths/password"
const SIGN_OUT = "/referrer_auths/sign_out"
let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const postMethod = (postBody, query) => axios.post(query, postBody, {
  headers: {
    'X-CSRF-Token': csrfToken
  }
});

const deleteMethod = (query) => axios.delete(query, {
  headers: {
    'X-CSRF-Token': csrfToken
  }
});

const patchMethod = (query) => axios.patch(query, {
  headers: {
    'X-CSRF-Token': csrfToken
  }
});

export function useFetchSettingsData({ ...requestOptions }) {
  return useQuery({
    ...requestOptions,
    queryKey: ['settings'],
    queryFn: () => axios.get(SETTINGS),
    select: (response) => response.data,
    placeholder: [],
  });
}

export function useUpdateSettings({ ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn:(postBody) => postMethod(postBody, SETTINGS),
  });
}

export function useUpdatePassword({ ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn: () => patchMethod(PASSWORD),
  });
}

export function useSignOut({ ...requestOptions }) {
  return useMutation({
    ...requestOptions,
    mutationFn: () => deleteMethod(SIGN_OUT),
  });
}