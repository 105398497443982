import React from "react";
import { Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";
import NewUserPage from "./components/new_user_page";
import FormSubmitted from "./components/form_submitted";
import Layout from "./components/layout";
import Dashboard from "./components/dashboard";
import Referrals from "./components/referrals";
import Settings from "./components/settings";
import Payouts from "./components/payouts";
import AffiliateLinks from "./components/affiliate_links";
import Help from "./components/help";
import Visitors from "./components/visitors";

const AppRoutes = (props) => {
  const { signUp, admin_review } = props;
  return (
    <div>
      {signUp ?
        <Routes>
          <Route path="/partners/sign_up" element={<NewUserPage />} />
          <Route path="/partners/form-submit" element={<FormSubmitted />} />
        </Routes> :
        <Routes>
          <Route path="/partners" element={<Layout {...props} />} >
            <Route path="dashboard" element={<Dashboard {...props} />} />
            {
              admin_review &&
              <>
                <Route path="referrals" element={<Referrals />} />
                <Route path="payouts" element={<Payouts />} />
                <Route path="affiliate-links" element={<AffiliateLinks />} />
                <Route path="visitors" element={<Visitors />} />
              </>
            }
            <Route path="settings" element={<Settings />} />
            <Route path="help" element={<Help />} />
          </Route>
        </Routes>
      }

    </div>
  );
};

AppRoutes.propTypes = {
  signUp: PropTypes.bool,
  admin_review: PropTypes.bool,
};

export default AppRoutes;
