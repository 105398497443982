import React from 'react';
import { BlockStack, Spinner } from '@shopify/polaris';
import PageHeader from '../page_header';
import { useFetchSettingsData } from './api';
import Body from './Body';
const Settings = () => {
  const { data, isLoading } = useFetchSettingsData({});
  return (
    <BlockStack gap={400}>
      <PageHeader title="Settings" />
      {
        isLoading || !data?.data ?
          <Spinner /> :
          <Body {...data.data} referralPlans={data.referral_plans} />
      }
    </BlockStack>
  );
};

export default Settings;